import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CastillosyPalacios from '../views/CastillosyPalacios.vue'
import RutasRecomendadas from '../views/RutasRecomendadas.vue'
import MicroSitios from '../views/Micrositios.vue'
import Entradas from '../views/Entradas.vue';
import EntradasDetalle from '../views/EntradasDetalle.vue';
import Reservar from '../views/ReservarV2.vue'
import ActividadesYRestaurantes from '../views/ActividadesYRestaurantes.vue'
import EventosSingulares from '../views/EventosSingulares.vue'
import ExperienciasExclusivas from '../views/ExperienciasSingulares.vue'
import RutaDetalle from '../views/RutasDetalle.vue'
// import Servicios from '../views/Servicios.vue'
import Actividades from '../views/Actividades.vue';
import Restaurantes from '../views/Restaurantes.vue';
import AlojamientosCercanos from '../views/AlojamientosCercanos.vue'
import RutasSearch from '../views/RutasSearch.vue'

import Carrito from '../views/Carrito.vue';
import CarritoOk from '../views/CarritoConfirm.vue';
import { KeepAlive } from 'vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeMobileDesign.vue'),
    meta:{
      KeepAlive: true
    }
  },
  {
    path: '/castillos',
    name: 'Castillos_y_Palacios',
    component: CastillosyPalacios
  },
  {
    path: '/rutas-recomendadas',
    name: 'Rutas_Recomendadas',
    component: RutasRecomendadas
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path:"/carrito",
    name:"Carrito",
    component: Carrito
  },
  {
    path:'/castillos/:monumentoNombre',
    name: 'Micro_Sitios',
    component: MicroSitios
  },
  {
    path:'/castillos/:monumentoNombre/bodas',
    name: 'BodasMicrositio',
    component: () => import('../views/Bodas.vue')
  },
  {
    path:'/castillos/:monumentoNombre/alojamiento',
    name: 'AlojamientosMicrositio',
    component: () => import('../views/Alojamientos.vue')
  },
  {
    path:'/castillos/:monumentoNombre/restaurante',
    name: 'RestauranteMicrositio',
    component: () => import('../views/Restaurante.vue')
  },
  {
    path:'/castillos/:monumentoNombre/espacios',
    name: 'EspaciosMicrositio',
    component: () => import('../views/Espacios.vue')
  },
  {
    path:'/castillos/:monumentoNombre/museo',
    name: 'MuseoMicrositio',
    component: () => import('../views/Museo.vue')
  },
  {
    path:'/castillos/:monumentoNombre/alojamientos',
    name: 'Alojamientos',
    component: AlojamientosCercanos
  },
  {
    path:'/castillos/:monumentoNombre/experiencias-exclusivas',
    name: 'ExperienciasCastillo',
    component: ExperienciasExclusivas
  },
  {
    // TODO: Use new layout
    path:'/servicios/visitas',
    name: 'ServiciosVisitas',
    component: () => import('../views/Servicios/MonumentosByService.vue')
  },
  {
    // TODO: Use new layout
    path:'/servicios/restaurantes',
    name: 'ServiciosRestaurante',
    component: () => import('../views/Servicios/MonumentosByRestaurante.vue')
  },
  {
    // TODO: Use new layout
    path:'/servicios/alojamiento',
    name: 'ServiciosAlojamiento',
    strict: true,
    component: () => import('../views/Servicios/MonumentosByAlojamiento.vue')
  },
  {
    path:'/servicios/bodas',
    name: 'ServiciosBoda',
    strict: true,
    component: () => import('../views/Servicios/MonumentosByBoda.vue')
  },
  {
    path:'/servicios/exposiciones',
    name: 'ServiciosExposiciones',
    component: () => import('../views/Servicios/MonumentosByExposiciones.vue')
  },
  {
    path:'/servicios/espacios',
    name: 'ServiciosEspacios',
    component: () => import('../views/Servicios/MonumentosByEspacios.vue')
  },

  {
    path:'/castillos/servicios/eventos-singulares',
    name: 'ServiciosEventos',
    component: () => import('../views/EventosSingulares.vue')
  },
  {
    path:'/castillos/servicios/experiencias-exclusivas',
    name: 'ServiciosExperiencias',
    component: () => import('../views/ExperienciasSingulares.vue')
  },
  {
    path:'/castillos/:monumentoNombre/eventos-singualres',
    name: 'EventosCastillo',
    component: EventosSingulares
  },

  {
    path:'/castillos/:monumentoNombre/restaurantes-y-actividades/:tipo/:id',
    name: 'Actividades_Restaurantes',
    component: ActividadesYRestaurantes
  },
  {
    path:'/castillos/tarifas/:monumentoNombre',
    name:'EntradasMonumento',
    component:Entradas
  },
  {
    path:'/experiencias-exclusivas/:monumentoNombre',
    name:'ExperienciasMonumento',
    component: () => import('../views/MicrositiosExperiencias.vue')
  },
  {
    path:'/eventos-singulares/:monumentoNombre',
    name:'EventosMonumento',
    component: () => import('../views/MicrositiosEventos.vue')
  },
  {
    path:'/actividades/:monumentoNombre',
    name:'Actividades',
    component:Actividades
  },
  {
    path:'/restaurantes/:monumentoNombre',
    name:'Restaurantes',
    component:Restaurantes
  },
  {
    path:'/castillos/tarifas/:monumentoNombre/:entradaId',
    name:"Detalle de Entradas",
    component: EntradasDetalle
  },
  {
    // path:'/carrito/:monumentoNombre/:entradaId',
    path:'/castillos/reservar/idvisita::entradaId/',
    name:"Reservar",
    component: Reservar
    // component: () => import('../views/ReservaProcess.vue')
  },
  {
    // path:'/carrito/:monumentoNombre/:entradaId',
    path:'/pack-experiencias/reservar',
    name:"ReservarPackExperiencias",
    component: () => import("../views/ReservasPackExperiencias.vue")
    // component: () => import('../views/ReservaProcess.vue')
  },
  {
    // path:'/carrito/:monumentoNombre/:entradaId',
    path:'/castillos/pack-experiencias/reservar',
    name:"ReservarPackMonumentos",
    component: () => import("../views/ReservasPackExperiencias.vue")
    // component: () => import('../views/ReservaProcess.vue')
  },
  {
    path:'/eventos-singulares',
    name:"Eventos",
    component: EventosSingulares
  },
  {
    path:'/experiencias-exclusivas',
    name:"ExperienciasExclusivas",
    component: ExperienciasExclusivas
  },
  {
    path:'/ruta/:rutaId',
    name:"Ruta",
    component: RutaDetalle
  },
  {
    path:'/blog',
    name:"Blogs",
    component: () => import('../views/Blogs.vue')
  },
  {
    path:'/blog/:blogId',
    name:"Blog",
    component: () => import('../views/Blog.vue')
  },
  {
    path:'/castillos/rutas/:monumentoId',
    name:"Rutas-Search",
    component: RutasSearch
  },
  {
    path:'/carrito-ok',
    name:"CarritoOk",
    component: CarritoOk    
  },
  {
    path:'/carrito-ok-belmonte',
    name:"CarritoOkBelmonte",
    component: () => import('../views/CarritoConfirmPixel.vue') 
  },
  {
    path:'/carrito-ko',
    name:"CarritoKO",
    component: () => import('../views/CarritoKO.vue')    
  },
  {
    path:'/quienes-somos',
    name:"Who",
    component: () => import('../views/About.vue')
  },
  {
    path:'/aviso-legal',
    name:"AvisoLegal",
    component: () => import('../views/AvisoLegal.vue')
  },
  {
    path:'/politica-calidad',
    name: 'PoliticaDeCalidad',
    component: () => import('../views/PoliticaDeCalidad.vue')
  },
  {
    path: '/politica-seguridad-informacion',
    name: 'InfoSecurityPolicy',
    component: () => import('../views/PoliticaDeSeguridadDeLaInformacion.vue')
  },
  {
    path: '/politica-responsabilidad-legal',
    name: 'LawyalSecurityPolicy',
    component: () => import('../views/PoliticaDeResponsabilidadLegal.vue')
  },
  {
    path:'/privacidad-cookies',
    name:"cookies",
    component: () => import('../views/PrivacidadCookies.vue')
  },
  {
    path:'/condiciones-generales',
    name:"condiciones",
    component: () => import('../views/CondicionesGenerales.vue')
  },
  {
    path:'/contacto',
    name:"contact",
    component: () => import('../views/Contacto.vue')
  },
  {
    path:'/contacto-reserva',
    name:"contactReserva",
    component: () => import('../views/ContactoReserva.vue')
  },

  {
    path:'/clientes/login',
    name:"clientesLogin",
    component: () => import('../views/Login.vue')
  },
  {
    path:'/clientes',
    name: 'clientes',
    component: () => import('../views/Clientes.vue')
  },
  {
    path:'/recuperar-password',
    name: 'ClientesResetPassword',
    component: () => import('../views/ClientesReset.vue')

  },
  {
    path:'/passwordReset',
    name: 'ClientesResetPasswordForm',
    component: () => import('../views/ClientesResetForm.vue')

  },

  {
    path:'/clientes/cambiaclave',
    name: 'clientesClave',
    component: () => import('../views/ClientesCambioPassword.vue')
  },
  {
    path:'/clientes/cambiaemail',
    name: 'clientesEmailChange',
    component: () => import('../views/ClientesCambioMail.vue')
  },

  {
    path:'/clientes/reservas',
    name:'clientesReservas',
    component: () => import('../views/ClientesReservas.vue')
  },
  {
    path:'/clientes/editar',
    name: 'clientesEditar',
    component: () => import('../views/ClientesEditar.vue')
  },
  {
    path:'/registro/monumentos',
    name: 'registroMonumentos',
    component: () => import('../views/Formularios/FormularioMonumentos.vue')
  },
  {
    path:'/registro/mayoristas',
    name:'registroMayoristas',
    component: () => import('../views/Formularios/FormularioMayoristas.vue')
  },
  {
    path:'/registro/promotor',
    name:'registropromotores',
    component: () => import('../views/Formularios/FormularioPromotores.vue')
  },
  {
    path: '/facturacion',
    name: 'GenrarFactura',
    component: () => import('../views/FacturacionView.vue')
  },
  {
    path: '/experiencias',
    name: 'Experiencias',
    component: () => import('../views/Experiencias/ExperiencesView.vue')
  },
  {
    path: '/resultados',
    name: 'Visitas',
    component: () => import('../views/VisitasFiltradas.vue')
  },
  {
    path: '/facturacion-ticketing',
    name: 'GenerarFacturaTicketing',
    component: () => import('../views/Facturacion/TicketingInvoice.vue')
  },
  {
    path: '/paquete/:paqueteNombre/:paqueteId',
    name: 'MicrositioPaquete',
    component: () => import('../views/MicrositiosPaquete.vue')
  },
  {
    path: '/paquetes/monumentos/:paqueteNombre/:paqueteId',
    name: 'MicrositioPaqueteMonumento',
    component: () => import('../views/MicrositiosPaquetesMonumentosView.vue')
  },
  {
    path: '/HomeMobile',
    name: 'HomeMobileDesign',
    component: () => import('../views/HomeMobileDesign.vue')
  },
  {
    path: '/privacy/remove-account',
    name: 'RemoveAccount',
    component: () => import('../views/Privacy/RemoveAccount.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { top: 0 }
  //   }
  // },
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

router.beforeEach( ( to, from, next) => {

  if( to.name === 'Reservar'){
    if( from.name !== null && from.name !== 'Detalle de Entradas' && from.name !== "Reservar" ){
      to.query.direct = true;
    }else{
        to.query.direct = false;
    }
  }
  next();
})

export default router