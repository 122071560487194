<template class="container-fluid bg-light">
    <div class="LayoutDefault px-0 pt-0" style="width:100%">
        <!-- <header> -->
        <mdb-navbar class="fw-bold bg-white sticky-top py-2" light expand="xl" >
            <mdb-navbar-brand>
                <img src="../assets/LOGO REPAHIS.png" @click="() => $router.push('/')" role="button" style="height:80px;width:auto;" class="align-text-top d-inline-block"/>
                <!-- <img src="../assets/CastillosLOGO.png" @click="() => $router.push('/')" role="button" style="height:80px;width:auto;" class="align-text-top img-fluid"/> -->
            </mdb-navbar-brand>

            <!-- <button class="navbar-toggler border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button> -->

            <mdb-navbar-toggler>
                <mdb-navbar-nav right >
                    <mdb-nav-item class="ms-auto">
                        <!-- <a class="nav-link" style="color:rgb(77,77,77) !important;" href="/">{{ $t('default.MAIN_MENU_INICIO')}}</a> -->
                        <router-link to="/" class="nav-link" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_INICIO')}}</router-link>
                    </mdb-nav-item> 
                    <mdb-nav-item class="nav-item border-nd-start ms-auto">
                        <a class="nav-link" style="color:rgb(77,77,77) !important;" href="/castillos">{{ $t('default.MAIN_MENU_MONUMENTOS')}}</a>
                    </mdb-nav-item>
                    <mdb-nav-item class="nav-item h-100 border-md-start ms-auto">
                        <a class="nav-link" href="/rutas-recomendadas" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_RUTAS')}}</a>
                    </mdb-nav-item>
                    <mdb-nav-item class="nav-item border-md-start ms-auto">
                        <a class="nav-link"  href="/blog" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_NOTICIAS') }}</a>
                    </mdb-nav-item>

                    <mdb-dropdown dropleft class="nav-item dropdown dropdown2 my-auto border-md-start ms-auto" color="danger">
                        <mdb-dropdown-toggle style="color:rgb(77,77,77) !important;" tag="a" navLink slot="toggle" waves-fixed>
                            {{$t('default.MAIN_MENU_SERVICIOS')}}
                        </mdb-dropdown-toggle>
                        <mdb-dropdown-menu>
                            <mdb-dropdown-item><a class="dropdown-item" href="/servicios/visitas">{{ $t('default.MAIN_MENU_SERVICES_VISITS') }}</a></mdb-dropdown-item>
                            <mdb-dropdown-item><a class="dropdown-item" href="/servicios/alojamiento">{{ $t('default.MAIN_MENU_SERVICES_ALOJAMIENTO') }}</a></mdb-dropdown-item>
                            <mdb-dropdown-item><a class="dropdown-item" href="/servicios/restaurantes">{{ $t('default.MAIN_MENU_SERVICES_RESTAURANTES') }}</a></mdb-dropdown-item>
                            <mdb-dropdown-item><a class="dropdown-item" href="/castillos/servicios/eventos-singulares">{{ $t('default.MAIN_MENU_SERVICES_EVENTOS') }}</a></mdb-dropdown-item>
                            <mdb-dropdown-item><a class="dropdown-item" href="/castillos/servicios/experiencias-exclusivas">{{ $t('default.MAIN_MENU_SERVICES_EXPERIENCIAS') }}</a></mdb-dropdown-item>
                            <mdb-dropdown-item><a class="dropdown-item" href="/servicios/bodas">{{ $t('default.MAIN_MENU_SERVICES_BODA') }}</a></mdb-dropdown-item>
                            <mdb-dropdown-item><a class="dropdown-item" href="/servicios/espacios">{{ $t('default.MAIN_MENU_SERVICES_ESPACIOS') }}</a></mdb-dropdown-item>
                            <mdb-dropdown-item><a class="dropdown-item" href="/servicios/exposiciones">{{ $t('default.MAIN_MENU_SERVICES_MUSEOS') }}</a></mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown>

                    <mdb-nav-item class="nav-item border-md-start ms-auto">
                        <a class="nav-link" href="/clientes/login" style="color:rgb(77,77,77) !important;">
                            <font-awesome-icon class="mt-1 me-1" icon="fa-circle-user"></font-awesome-icon>
                            {{ $t('default.MAIN_MENU_ACCEDER')}}
                        </a>
                    </mdb-nav-item>

                    <mdb-dropdown class="my-auto border-md-start d-md-flex ms-auto d-none dropdown ms-auto" color="danger" end>
                        <mdb-dropdown-toggle tag="a" navLink slot="toggle" waves-fixed>
                            <font-awesome-icon class="my-auto" icon="fa-cart-shopping" v-bind:class="{'fa-bounce':nuevoItemAgregado}"></font-awesome-icon>
                            {{ticketsAmount}}
                        </mdb-dropdown-toggle>
                        <mdb-dropdown-menu>
                            <mdb-dropdown-item class="dropdown-item" v-for="(item) in itemsInCart" v-bind:key="item.uuid">
                                <entrada-resumen class="me-auto" :item="item"/>
                            </mdb-dropdown-item>
                            <mdb-dropdown-item><hr class="dropdown-divider"></mdb-dropdown-item>
                            <mdb-dropdown-item>
                                <a class=" dropdown-item nav-link"><strong>{{ $t('default.MENSAJE_IMPORTE_TOTAL') }} {{(totalAPagar).toLocaleString()}} €</strong></a>
                            </mdb-dropdown-item>
                            <mdb-dropdown-item class="mx-auto">
                                <a class="dropdown-item nav-link" href="/carrito"><strong class="mx-auto">{{ $t('default.MENSAJE_VER_CARRITO') }}</strong></a>
                            </mdb-dropdown-item>
                            <mdb-dropdown-item class="d-flex py-0" v-bind:class="{'d-none': !mostrarTiempo }">
                                <hr />
                                <p class="ms-auto me-2">{{ timeCounter }}</p>
                            </mdb-dropdown-item>

                        </mdb-dropdown-menu>
                    </mdb-dropdown>

                </mdb-navbar-nav>
                    <span class="ms-auto ms-lg-0 d-flex">
                        <mdb-dropdown class="ms-auto" end>
                            <mdb-dropdown-toggle class="text-uppercase" tag="a" style="color:rgb(77,77,77) !important;" navLink slot="toggle" waves-fixed>
                                <img :src="selectedFlag" alt="selected language flag" style="width: 20px; margin-right: 8px; height: auto;">   
                                {{ selectedLanguage }}
                            </mdb-dropdown-toggle>
                            <mdb-dropdown-menu class="ms-auto text-uppercase">
                                <mdb-dropdown-item v-for="lang of availableLanguages" :key="lang.code" 
                                    @click="() => changeLanguage( lang.code )">
                                    <img :src="lang.flag" :alt="lang.name" style="width: 20px; margin: 8px; height: auto;"/>
                                    {{ lang.name }}
                                </mdb-dropdown-item>
                            </mdb-dropdown-menu>
                        </mdb-dropdown>
                    </span>
            </mdb-navbar-toggler>
        </mdb-navbar>            
        <!-- </header> -->
        <main class="LayoutDefault__main bg-light container-fluid px-0">

            <slot/>

            <div class="justify-content-start d-none fixed-button big-text me-4" v-bind:class="{'d-lg-flex': mostrarTiempo }" role="button">
                <div class="p-2 bg-white rounded opacity-75 ms-3 mb-3 shadow-lg d-flex"  @click="() => { $router.push('/carrito') }">
                    <p class="ms-2 me-2 my-auto font-weight-bold" >{{ timeCounter }}</p>
                    <font-awesome-icon class="text-align-start" size="3x" icon="fa-clock me-auto ms-3 my-auto"></font-awesome-icon>
                </div>
            </div>

            <div class="fixed-button d-lg-none d-flex" >
                <div class="bg-white opacity-75 rounded ms-3 shadow-lg d-flex" ref="carrito" role="button" @click="() => { $router.push('/carrito') }">
                    <div v-bind:class="{'d-none': !mostrarTiempo }">
                        <small class="ms-auto me-2 big-text pe-2">{{ timeCounter }}</small>
                    </div>

                    <font-awesome-icon class="text-align-start my-2 ps-2" size="2x" icon="fa-cart-shopping me-auto" v-bind:class="{'fa-bounce':nuevoItemAgregado}"></font-awesome-icon>
                    <span class="big-text">{{ticketsAmountSmall}}</span>
                </div>
            </div>
            
        </main>
        <div class="d-flex w-100 newsletter py-md-5" style="min-height:18rem;" v-bind:style="{backgroundImage:newsletterImage}">
            <div class="d-md-flex d-none mx-auto my-auto" style="min-height:341px; width: 28vw; min-width:300px;">
                <!-- <div class="mx-auto px-3 pt-3 pb-3" style="border-radius:10px; background-color: rgba(255, 255, 255, 0.85);">
                    <form class="pb-3">
                        <h5 class="mx-auto"><small>{{ $t("default.NEWSLETTER_TITLE") }}</small></h5>
                        <p class="spainhn-small-text"><strong>{{ $t("default.NEWSLETTER_SUBTITLE") }}</strong></p>
                        <b-form-group class="my-0">
                            <p class="spainhn-text my-0"><strong>{{ $t("default.NEWSLETTER_EMAIL") }}</strong></p>
                            <b-form-input type="email" size="sm" style="border-radius:10px;" class="w-50 mx-auto" placeholder="hola@ejemplo.com"></b-form-input>
                        </b-form-group>
                        <b-form-checkbox class="text-start d-flex my-3">
                            <small>{{ $t("default.NEWSLETTER_ACEPTO") }} <a class="link-dark" href="/condiciones-generales">{{ $t("default.NEWSLETTER_TERMINOS") }}</a>Spain  Heritage Network</small>
                        </b-form-checkbox>
                        <mdb-btn color="indigo" class="spainhn-button">{{ $t("default.SPAINHN_BUTTON_SUBSCRIBE") }}</mdb-btn>
                        <a class="spainhn-small-text mt-3 link-dark d-block" href="/privacidad-cookies"><small><strong>{{ $t("default.NEWSLETTER_DATA") }}</strong></small></a>
                    </form>
                </div> -->
            </div>

            <!-- <div class="d-flex d-md-none mx-auto my-auto" style="width: 300px">
                <div class="mx-auto px-3 pt-1 pb-1" style="border-radius:10px; background-color: rgba(255, 255, 255, 0.85);">
                    <form class="pb-3">
                        <h5 class="mx-auto"><small style="font-size: 10px; color: #000000">{{ $t("default.NEWSLETTER_TITLE") }}</small></h5>
                        <p class="fw-bold" style="font-size: 10px; color: #850b0b">{{ $t("default.NEWSLETTER_SUBTITLE") }}</p>
                        <b-form-group class="my-0">
                            <p class="my-0 fw-bold" style="font-size: 10px; color: #850b0b">{{ $t("default.NEWSLETTER_EMAIL") }}</p>
                            <b-form-input type="email" size="md" style="border-radius:10px;" class="mx-auto" placeholder="hola@ejemplo.com"></b-form-input>
                        </b-form-group>
                        <b-form-checkbox class="text-start d-flex my-0 pb-0">
                            <small style="font-size: 7px; color: #000000">{{ $t("default.NEWSLETTER_ACEPTO") }}<a style="font-size: 7px" class="link-dark" href="/condiciones-generales">{{ $t("default.NEWSLETTER_TERMINOS") }}</a>Spain  Heritage Network</small>
                        </b-form-checkbox>
                        <a class="my-0 py-0 link-dark d-block" href="/privacidad-cookies"><small style="font-size: 7px; color: #850b0b;"><strong>{{ $t("default.NEWSLETTER_DATA") }}</strong></small></a>
                        <mdb-btn color="indigo" class="spainhn-button" size="sm">{{ $t("default.SPAINHN_BUTTON_SUBSCRIBE") }}</mdb-btn>
                    </form>
                </div>

            </div> -->
        </div>
         <footer class="LayoutDefault_footer bg-dark text-white pb-2">
            <!-- <cookie-law theme="dark-lime">
                <div slot="message">
                </div>
                <div slot-scope="props">
                    <div class="d-flex w-100 copyright-footer">
                        <p class="ms-auto me-4 my-auto" style="text-align:left">
                            Este sitio web utiliza cookies propias para mejorar su experiencia de navegación. <router-link to="privacidad-cookies">Políticas de privacidad</router-link>
                        </p>
                        <div class="d-flex ms-auto" style="z-index:1200 !important;">
                            <button class="skew ms-auto" @click="props.accept"><span>Acepto</span></button>            
                            <button class="skew" @click="props.close"><span>Ignorar</span></button>
                        </div>

                    </div>
                </div>
            </cookie-law> -->
            
            <mdb-row class="pt-4 footer-wrapper m-0" style="padding-bottom:5.5rem;justify-content: center">
                <mdb-col col="6" lg="3" class="ps-md-4 ps-0 mb-md-4 mb-md-0 px-md-5">
                    <img src="@/assets/LOGO REPAHIS (BLANCO SIN FONDO).png" class="img-fluid"/>
                    <div class="d-flex d-md-none flex-column align-items-start text-start mt-4 text-start spainhn-text">
                        <!-- <small>info@spainheritagenetwork.com</small> -->
                        <div class="d-flex mt-3 text-start">
                            <a href="https://www.facebook.com/redpatrimoniohistorico?_rdr" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-facebook-square" size="2x"></font-awesome-icon></a>
                            <a href="https://www.instagram.com/redpatrimoniohistorico/" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-instagram-square" size="2x"></font-awesome-icon></a>
                            <a href="https://es.linkedin.com/company/redpatrimoniohistorico" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-linkedin" size="2x"></font-awesome-icon></a>
                        </div>
                    </div>

                </mdb-col>
                <mdb-col col="6" lg="3" class="px-1">
                    
                        <div class="d-flex align-items-start">
                            <small class="" style="font-size:10px;text-align:left">SPAIN HERITAGE NETWORK</small>
                        </div>
                        <div class="d-flex flex-column mt-1 text-start spainhn-text">
                            <a href="/quienes-somos" class="link-light"><small style="font-size:9px;text-align:left">{{$t('default.FOOTER_WHO')}}</small></a>
                            <a href="/aviso-legal" class="link-light"><small style="font-size:9px;text-align:left">{{$t('default.FOOTER_LEGAL')}}</small></a>
                            <a href="/privacidad-cookies" class="link-light"><small style="font-size:9px;text-align:left">{{$t('default.FOOTER_PRIVACY_AND_COOKIES')}}</small></a>
                            <a href="/condiciones-generales" class="link-light"><small style="font-size:9px;text-align:left">{{$t('default.FOOTER_SELL_GENERALS')}}</small></a>
                            <a href="/contacto" class="link-light"><small style="font-size:9px;text-align:left">{{$t('default.FOOTER_CONTACT')}}</small></a>
                            <a href="/politica-calidad" class="link-light"><small style="font-size:9px;text-align:left">Política de gestión de calidad</small></a>
                            <a href="/politica-seguridad-informacion" class="link-light"><small style="font-size:9px;text-align:left">Política de seguridad de la información</small></a>
                            <a href="/politica-responsabilidad-legal" class="link-light"><small style="font-size:9px;text-align:left">Política de complience legal</small></a>
                            <a href="https://admin.spainheritagenetwork.com" class="link-light"><small style="font-size:9px;text-align:left">  {{$t('default.FOOTER_ACCESS')}}  </small></a>
                        </div>
                    
                </mdb-col>
                <mdb-col sm="6" lg="3" class="ps-0 ps-md-4 d-none d-md-inline-block">
                    <div class="d-flex align-items-start text-start">
                        <small class="fw-bold" style="font-size:small">{{ $t("default.FOOTER_CONTACT_TITLE") }}</small>
                    </div>
                    <div class="d-flex flex-column align-items-start text-start mt-3 text-start spainhn-text">
                        <small>Email: info@spainheritagenetwork.com</small>
                        <div class="d-flex mt-3 text-start">
                            <a href="https://www.facebook.com/redpatrimoniohistorico?_rdr" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-facebook-square" size="2x"></font-awesome-icon></a>
                            <a href="https://www.instagram.com/redpatrimoniohistorico/" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-instagram-square" size="2x"></font-awesome-icon></a>
                            <a href="https://es.linkedin.com/company/redpatrimoniohistorico" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-linkedin" size="2x"></font-awesome-icon></a>
                        </div>
                    </div>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col col="12" md="6" class="ps-4 mb-4 mb-md-0">
                    <img src="@/assets/LOGO_NEXTGENERATIONEU.jpg" class="img-fluid" style="width:320px; height: auto;"/>
                </mdb-col>
                <mdb-col col="12" md="6" class="ps-4 mb-4 mb-md-0">
                    <img src="@/assets/MIT_Gob.Web_SET.png" class="img-fluid" style="width:320px; height: auto;"/>
                </mdb-col>
            </mdb-row>
        </footer>
    </div>
</template>

<script>

/* eslint-disable vue/no-unused-components */
const defaultLayout = 'AppLayoutDefault';
import EntradaResumen from '../components/EntradaResumen.vue'
import CountryFlag from 'vue-country-flag'
// import CookieLaw from 'vue-cookie-law';
import ApiService from '../services/ApiService';
import moment from 'moment';

// import IconBase from '../components/IconBase.vue';
// import IconBaseStrech from '../components/IconBaseStrech.vue'
// import IconBase from '../components/IconBase.vue';

const el = document.querySelector(".spainhNavbar")
const observer = new IntersectionObserver( 
  ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
  { threshold: [1] }
);

import {
    mdbSticky
} from "mdbvue";

// observer.observe(el);

export default {
    name:"SpainHeritageAdminLayout",
    directives:{
        mdbSticky
    },
    components:{

        CountryFlag,
        EntradaResumen,
        // CookieLaw
        // IconBase,
        // IconBaseStrech
        // IconBase
    },
    data(){
        return{
            newsletterImage: `url(${require('@/assets/newsletter-background.jpg')})`,
            idioma: 'es',
            scrolling: false,
            countdownID: null,
            numeroDeItems: 0,
            // Contamos hasta quince minutos antes de borrar la entrada
            countdownMinutes: 15,
            carritoUuid: null,
            expTime: "15:00:00",
            carritoTime: null,
            timeCounter: null,
            timerId: null,
            availableLanguages: [
                { name: "Español", code: 'es', flag: require('@/assets/flags/Spain/es.webp') },
                { name: 'Catalán', code: 'ca', flag: require('@/assets/flags/Catalan/ca.webp') }, 
                { name: 'Inglés', code: 'en', flag: require('@/assets/flags/UK/en.webp') },
                { name: 'Francés', code: 'fr', flag: require('@/assets/flags/France/fr.webp') },
                { name: 'Portugués', code: 'pt', flag: require('@/assets/flags/Portugal/pt.webp') },
                { name: 'Italiano', code: 'it', flag: require('@/assets/flags/Italy/it.webp') },
                { name: 'Alemán', code: 'de', flag: require('@/assets/flags/Germany/de.webp') },
                { name: 'Ruso', code: 'ru', flag: require('@/assets/flags/Russia/ru.webp') },
                { name: 'Chino Mandarín', code: 'zh', flag: require('@/assets/flags/China/zh.webp') },
                { name: 'Japonés', code: 'ja', flag: require('@/assets/flags/Japan/ja.webp') },
                { name: 'Árabe', code: 'ar', flag: require('@/assets/flags/SaudiArabia/ar.webp') },
                { name: 'Hindú', code: 'hi', flag: require('@/assets/flags/India/hi.webp') },
            ]
            // cartPosition: '487px'
            
        }
    },
    methods:{
        reload(){
            window.location.reload();
        },
        changeLanguage( newLang ){

            this.$i18n.locale = newLang;
            this.idioma = newLang;
            this.$store.commit( "setIdioma", newLang );
            window.location.reload();
        },
        resetCountdown(){
            let $vm = this;
            $vm.countdownMinutes = 15;

            if( $vm.countdownID == null) {
                $vm.startCountd
            }
        },
        handleScroll(){
            if(window.pageYOffset > 10.0)
                this.scrolling = true;
            else
                this.scrolling = false;
        },

        updateLanguage(language) {
        const selected = this.availableLanguages.find(lang => lang.name === language);
        if (selected) {
            this.$store.dispatch('updateIdioma', selected.code);
        }
    },
        // startCartCountdown(){
        //     let $vm = this;
        //     $vm.countdownID = setInterval( () =>{
        //         $vm.$store.commit('updateCountdown');
        //     } )
        // },
        /**
         * 
         */
        triggerTimer( expTime ){
            let $vm = this;
            // $vm.carritoTime = moment.utc(expTime);
            if( !this.timerId ){

                this.carritoTime = moment.utc( this.$store.getters.getExp ?? 0 );

                this.timerId = setInterval( () => { 
                    let now = moment();
                    let diffTime = moment.utc( this.carritoExpTime ) - now;
                    let duration = moment.duration( diffTime - 1000, 'milliseconds' )

                    let minutes = duration.minutes();
                    let seconds = duration.seconds();

                    if( minutes < 10 ){
                        minutes = "0" + minutes;
                    }

                    if(seconds < 10){
                        seconds = "0" + seconds;
                    }
                    
                    $vm.timeCounter = (  minutes + ":" + seconds );
                    // this.timeCounter = moment(duration).format("mm:ss");

                    if( duration <= 0 ){
                        $vm.timeCounter = "0:00";
                        clearInterval( $vm.timerId );
                        $vm.timerId = null;
                        this.$store.commit('removeAllItems')
                    }
                    else if( this.$store.getters.itemsInCart.length == 0 ){
                        $vm.timeCounter = "0:00";
                        clearInterval( $vm.timerId );
                        $vm.timerId = null;
                        this.$store.commit('removeAllItems')
                    }
                }, 1000 )
            }
        }


    },
    computed: {
        carritoExpTime(){
            return this.$store.getters.getExp ?? 0;
        },
        layout(){
            const layout = this.$route.meta.layout || defaultLayout;
            return () => import(`@/layouts/${layout}.vue`)
        },
        ticketsAmount(){

            let amount = this.$store.getters.numberOfTickets;

            if(amount == 0){
                // return this.$t('default.MAIN_MENU_CARRITO');
                return "";
            }
            else{
                return amount + " Visita(s)";
            }
        },
        ticketsAmountSmall(){
            let amount = this.$store.getters.numberOfTickets;

            if(amount == 0){
                return "(0)";
            }
            else{
                return `(${amount})`;
            }

        },
        itemsInCart(){
            let $vm = this;
            let items = this.$store.getters.itemsInCart;
                        
            $vm.numeroDeItems = items.length;

            return this.$store.getters.itemsInCart;
        },
        nuevoItemAgregado(){
            return this.$store.getters.cartAnimation;
        },
        totalAPagar(){
            let totalAPagar = this.$store.getters.total;

            if(totalAPagar != null){
                return totalAPagar.toLocaleString('es-ES', { maximumFractionDigits:2, minimumFracionDigits:2 } );
            }
            else{
                return "ups"
            }
            // return this.$store.getters.total;
        },
        isFixed(){
            if(this.scrolling ) {
                return true;
            }
            else{
                return false;
            }
        },
        cartPosition(){
            let viewportHeight = window.innerHeight;
            // let carritoHeight = this.$refs.carrito.style.height;
            // console.log(carritoHeight)
            let position = `${viewportHeight - (viewportHeight * .46)}px `
            return position;
        },

        mostrarTiempo(){
            // let carrito = this.$store.getters.cartStatus;

            // console.log(carrito);
            // if( !carrito.uuid ){
            //     // this.carritoTime = moment.utc(carrito.exp);
            //     return true;
            // }
            // else{
            //     return false;
            // }

            // if( this.$store.getters.getTimer ){

            // }

            if( this.$store.getters.getExp ){
                this.triggerTimer( this.$store.getters.getExp )
                return true;
            }
            else{
                return false;
            }
    
        },
        selectedLanguage(){
            const _initialLang = this.$store.getters.getIdioma ?? "es";
            return _initialLang;
        },

        selectedFlag() {
        const selected = this.availableLanguages.find(lang => lang.code === this.selectedLanguage);
        return selected ? selected.flag : require('@/assets/flags/Spain/es.webp');
    }
    },
    async created(){
        let $vm = this;
        $vm.$i18n.locale = $vm.$store.getters.getIdioma ?? 'es';
        // console.log($vm.$i18n.locale);
        if( $vm.$i18n.locale == null){
            $vm.$i18n.locale == 'es';
        }

        this.carritoUuid = $vm.$store.getters.cartStatus.uuid;
        // Si ya hay un carrito activo, preguntamos al backend si ya se pagó el carrito
        if( this.carritoUuid ){
            // Dependiendo de la respuesta, en caso de que se obtenga que el producto ya se pagó,
            // entonces se procede a eliminar el id del carrito, para que si se agrega una nueva reserva
            // se cree un nuevo carrito
            let result = await ApiService.getCarrito( this.carritoUuid )
            let carritoInfo = result.data;
            if( !carritoInfo || carritoInfo.status === true ){
                console.log( "Borrar carrito" )
                this.$store.commit('removeAllItems');
            }
        }
        // En caso de que no haya carrito activo, revisamos si hay items guardados y los eliminamos para 
        // que si se agregan nuevos productos, se haga asociado a un carrito nuevo
        else{
            if( this.$store.getters.numberOfTickets ){
                this.$store.commit('removeAllItems')
            }
        }
        $vm.$store.commit('setIdioma', this.$i18n.locale);
        window.addEventListener('scroll', this.handleScroll);

        if($vm.$i18n.locale == null){
            $vm.$i18n.locale = 'es';
        }
        if(this.$i18n.locale == 'en'){
            // this.$i18n.locale="es";
            $vm.idioma = 'gb';
        }
        else if(this.$i18n.locale == 'es'){
            // this.$i18n.locale = "en";
            $vm.idioma = 'es';
        }
        else if(this.$i18n.locale == 'fr'){
            $vm.idioma = 'fr';
        }
        else if(this.$i18n.locale == 'de'){
            $vm.idioma = 'de';
        }

        // De esta manera sabemos si se agregan o quitan items
        // La idea es que al agregar items, se reinicie el conteo,
        // pero si se quitan items, el conteo sigue igual
        $vm.numeroDeItems = $vm.$store.getters.numberOfTickets;
    },
    mounted(){
        // console.log(el);
        // observer.observe(el);
        this.triggerTimer();

    },
    destroyed(){
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style scoped>

    .fixed-button{
        position: fixed;
        bottom: 10px;
        left: 0px;
        z-index: 10;
    }
    .timer {
        font-size: 1.4rem;
        font-weight: 600;
        position: fixed;
        top: 30px;
        z-index: 3;
    }
    .big-text {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .newsletter{
        /* background-image: `url(${require('@/assets/newsletter-background.jpg')})`; */
        /* background-image: url('../assets/newsletter-background.jpg'); */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .dropdown-flag{
        background: #fff;
        border-radius: 10px;
        border:1px solid gray;
    }
    .dropdown-flag .dropdown-item{
        padding: 0.75rem 0rem;
    }
    .dropdown-menu.flag-dropdown-menu{
        border-radius: 12px;
        box-shadow: 0px 1px 10px #817e7e;
        border: 0;
        min-width:8rem;
    }
    .dropdown-flag .dropdown-item:hover{
        background: none;
    }
    /* styles for when the header is in sticky mode */
    .is-pinned {
        background-color: rgba(255,255,255,1) !important;
    } 

    .is-not-pinned{
        background-color: rgba(255,255,255,0.6) !important;
    }
    .navbar-toggler:focus{
        box-shadow: none;
    }
    @media(max-width:1440px){
        .LayoutDefault_footer .footer-logo img{
            width:80% !important;
        }
        /* .spainhNavbar .navbar-collapse{
            display: block !important;
        } */
        ul.navbar-nav{
            justify-content: end;
        }
        .flag-menu-main{
            text-align: right;
            margin-top: 10px;
        }
        .flag-menu-main .dropdown-flag{
            display: inline-block;
        }
        .spainhNavbar .navbar-brand{
            margin-left: 20px !important;
        }
    }
    @media(max-width:1199px){
        .spainhn-main-menu-text{
            font-size: 14px !important;
        }
    }
     @media(max-width:991px){
        .LayoutDefault_footer .footer-logo img{
            width:initial !important;
            max-width: 200px;
        }
        .LayoutDefault_footer .col-sm-6{
            text-align: left;
            padding: 0 !important;
        }  
        .LayoutDefault_footer .footer-wrapper{
            padding: 24px 30px 20px 30px !important;
            justify-content: start !important;
        }
        .LayoutDefault_footer .footer-wrapper>div{
            padding-bottom:20px !important;
        }
        .flag-menu-main{
            text-align: center;
        }
        .dropdown-menu.flag-dropdown-menu{
            right: 50%;
            left: 50%;
            transform: translateX(-50%);
        }
        .spainhNavbar{
            padding-right: 0 !important;
        }
        .spainhNavbar .navbar-brand img{
            height: 60px !important;
        }
        .spainhNavbar .collapse:not(.show){
            display: none !important;
        }
     }
    @media(max-width:640px){
        .copyright-footer{
            display:block !important;
        }
        .Cookie--dark-lime{
            padding: 0 1.25em !important;
        }
        .Cookie--dark-lime p{
            margin-bottom:20px !important;
        }
    }

    @media(width < 408px){
        .dropdown2 div {
            transform: translate3d(-250px, 0, 0)
        }
    }
</style>


<style>
</style>