<template>
    <default-layout>
        <!-- <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillos" v-html="`${monumentoNombre} >>`"></p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text"> {{ $t('default.NAVBAR_TICKET') }} </p>
            </div>
        </div> -->

        <white-loader :loading="loading"/>
        <!-- <minimalist-breadcrumb :title="monumentoNombre" style="margin-bottom: 15px;"/> -->

        <div style="min-height: 500px;" v-if="loading">
        </div>

        <div class="container-fluid px-5 mt-4">
            <div v-for="monumento in monumentosAgrupados" :key="monumento.monumentoId" class="mb-4">
                <div>
                    <p class="spainhn-sections-title uppercase" style="font-size: 1.4rem; margin-bottom: 1.5rem;">{{ monumento.nombre }}</p>
                </div>

                <div v-if="monumento.servicios.visitas.length > 0">
                    <p class="spainhn-sections-title">{{ $t("default.MICROSITES_BOOKING") }}</p>
                    
                    <div class="d-md-flex d-none mb-4">
                        <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                            <cards v-for="(entrada, index) in monumento.servicios.visitas" 
                                v-bind:key="index" 
                                @goToEntrada="goToEntrada($event)"
                                :entradaId="entrada.visitaId"
                                :monumentoNombreURL="monumentoUrl"
                                :imageUrl="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`"
                                :apiDeVentasUrl="apiDeVentasActive ? `${apiDeVentasUrl}?idProducto=${entrada.oldId}`: ''"
                                :title="entrada.webName" 
                                :monument="entrada.monumentName" 
                                :precioOrientativo="entrada.precioOrientativo ? $t('default.CARDS.START_FROM', {msg: getPrice(entrada.precioOrientativo)}): ''"
                            />
                        </div>
                    </div>

                    <div class="mobile-cards-container d-md-none">
                        <recommendations-card 
                            v-for="(entrada, index) of monumento.servicios.visitas" 
                            v-bind:key="index"
                            :title="entrada.webName"
                            :showDefault="false"
                            :text="$t('default.CARDS.PLACE', { msg: entrada.monumentName})"
                            :price-description="entrada.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(entrada.precioOrientativo)} ):''"
                            :image-url="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`"
                            :showBuyButton="true"
                            @goTo="goToEntrada( { entradaId: entrada.visitaId } )"
                            @buy="() => goToBuy( entrada.visitaId, entrada.oldId )"
                        />
                    </div>
                </div>

                <div v-if="monumento.servicios.paquetes.length > 0">
                    <p class="spainhn-sections-title">Tours</p>
                    
                    <div class="d-md-flex d-none mb-4">
                        <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                            <ResultsCards
                                v-for="paqueteM in monumento.servicios.paquetes" 
                                :key="paqueteM._id"
                                class="mx-auto" 
                                :title="paqueteM.name" 
                                :entradaId="paqueteM._id"
                                :showDefault="false"
                                :monumentoNombreURL="paqueteM.name"
                                :monument="$t('default.CARDS.PLACE', {'msg': paqueteM.monumentoId.nombre})"
                                :precioOrientativo="paqueteM?.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(paqueteM?.precioOrientativo)}) : ''"
                                :imageUrl="`${imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`"
                                tipo="paquetes_monumentos"
                                @goToEntrada="() => goToMicrositioPaqueteMonumento({ name: paqueteM.name, id: paqueteM._id })"
                                @buy="() => goToBuy(paqueteM, true)"
                            />
                        </div>
                    </div>

                    <div class="mobile-cards-container d-md-none">
                        <recommendations-card
                            v-for="paqueteM in monumento.servicios.paquetes" 
                            :key="paqueteM._id"
                            :title="paqueteM.name"
                            :showDefault="false"
                            :text="$t('default.CARDS.PLACE', {'msg': paqueteM.monumentoId.nombre})"
                            :price-description="paqueteM.precioOrientativo ? $t('default.CARDS.START_FROM', {msg: getPrice(paqueteM.precioOrientativo)}) : ''"
                            :image-url="`${imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`"
                            :showBuyButton="true"
                            @goTo="() => goToMicrositioPaqueteMonumento({ name: paqueteM.name, id: paqueteM._id })"
                            @buy="() => goToBuy2(paqueteM, true)"
                        />
                    </div>
                </div>

                <div v-if="monumento.servicios.eventos.length > 0">
                    <p class="spainhn-sections-title">{{ $t("default.MAIN_MENU_SERVICES_EVENTOS") }}</p>
                    
                    <div class="d-md-flex d-none mb-4">
                        <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
                            <cards v-for="(evento, index) in monumento.servicios.eventos" 
                                v-bind:key="index" 
                                @goToEntrada="goToEntrada($event)"
                                :tipo="'Evento'"
                                :entradaId="evento.visitaId"
                                :monumentoNombreURL="monumentoUrl"
                                :imageUrl="`${imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`"
                                :title="evento.webName" 
                                :monument="monumento.nombre"
                                :precioOrientativo="evento.precioOrientativo ? $t('default.CARDS.START_FROM', {msg: getPrice(evento.precioOrientativo)}): ''"
                                :date="evento.fechas"
                            />
                        </div>
                    </div>

                    <div class="mobile-cards-container d-md-none">
                        <recommendations-card 
                            v-for="(evento, index) of monumento.servicios.eventos" 
                            v-bind:key="index"
                            :title="evento.webName"
                            :showDefault="false"
                            :text="$t('default.CARDS.PLACE', {msg: monumento.nombre})"
                            :price-description="evento.precioOrientativo ? $t('default.CARDS.START_FROM', {'msg': getPrice(evento.precioOrientativo)}) : ''"
                            :image-url="`${imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`"
                            :showBuyButton="true"
                            @goTo="goToEntrada( { entradaId: evento.visitaId } )"
                            @buy="() => goToBuy( evento.visitaId, evento.oldId )"
                        />
                    </div>
                </div>
            </div>
        </div>

        <mdb-container fluid class="mt-3">
            <ComponentsCloud />
        </mdb-container>
        <!-- <trade-spoting v-if="monumentoUrl == 'castillo-de-belmonte'"></trade-spoting> -->
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
// import IconBase from '../components/IconBase.vue'
// import IconCasttle from '../components/icons/IconCasttle.vue';
import Cards from '../components/Cards.vue'
import ClorianService from '../services/ClorianService';
// import MinimalistBreadcrumb from '../components/Breadcrumbs/MinimalistBreadcrumb'
import WhiteLoader from '../components/Loaders/WhiteLoader'
import RecommendationsCard from '../components/RecommendationsCard'
import ComponentsCloud from '../components/ComponentsCloud';
import ResultsCards from "../components/ResultsCards";
// import TradeSpoting from '../components/TradeSpoting.vue';

export default {
    components:{
        DefaultLayout,
        ResultsCards,
        // IconBase,
        // IconCasttle,
        Cards,
        // MinimalistBreadcrumb,
        WhiteLoader,
        RecommendationsCard,
        ComponentsCloud
        // TradeSpoting
    },
    data(){
        return{
            address: null,
            // monumentoNombre: null,
            monumentoNombres:[],
            monumentoUrl: null,
            monumentoId: null,
            entradas: [],
            imagenesUrl: null,
            eventos: [],
            clorian: false,
            apiDeVentasActive: false,
            apiDeVentasUrl: "",
            loading: false,
            clorianPos: null,
            paquetes: [],
            monumentName: "",
            monumentosAgrupados: [],
        }
    },
    setup() {
        
    },
    methods:{
        encodeUrl(name = '') {
            let rawName = name.replaceAll(',', '');
            rawName = rawName.replaceAll('.', '');

            let sanitizedName = rawName.normalize("NFD").replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, '')
            let sanitizedNameLowercase = sanitizedName.toLowerCase().replace(/[\u0020]/g, '-');

            return sanitizedNameLowercase
        },
        goToMicrositioPaqueteMonumento( { name , id } ){
            console.log('🚀 ~ goToMicrositioPaqueteMonumento ~ name:', name)
            this.$router.push( { name: 'MicrositioPaqueteMonumento', params: { paqueteNombre: this.encodeUrl(name) || 'name', paqueteId: id } } )
        },
        goToBuy2( data, paqueteMonumento=false ) {
            if( data.apiDeVentasActive && data.apiDeVentasUrl !== 'clorian' ){
                this.$toast.success(this.$t("default.NOTIFICATIONS.REDIRECT"));
                setTimeout( () => {
                    window.open(`${data.apiDeVentasUrl}?idProducto=${data.oldId}`, '_blank');
                }, 2000 )
            }else{

                if( data.imageLabel === 'paquetes_prestadores'){
                    this.$router.push({ name: "ReservarPackExperiencias",  query: { tipo: 'paquete', id: data._id } } );
                }
                else if(data.imageLabel === "servicios_prestadores"){
                    this.$router.push({ name: "ReservarPackExperiencias",  query: { tipo: 'servicio', id: data._id } } );
                }
                else if( paqueteMonumento ){
                    this.$router.push({ name: "ReservarPackMonumentos",  query: { tipo: 'monumentos', id: data._id } } );
                }
                else{
                    this.$router.push(`/castillos/reservar/idvisita:${data.entradaId}`)
                }
            }
        },
        goToBuy( visitaId, oldId ){
            if( this.apiDeVentasActive && this.apiDeVentasUrl === 'clorian'){
                console.log("Comprar en clorian");
            }
            else if( this.apiDeVentasActive ){
                this.$toast.success(this.$t("default.NOTIFICATIONS.REDIRECT"));
                setTimeout( () => {
                    window.open(`${this.apiDeVentasUrl}?idProducto=${oldId}`, '_blank');
                    this.movingToCart = false;
                }, 2000 )
            }else{
                this.$router.push(`/castillos/reservar/idvisita:${visitaId}`)
            }
        },
        goToEntrada(event){
            if( this.monumentoUrl === 'castillo-de-san-pedro-de-jaca-(ciudadela)'){
                window.open(process.env.VUE_APP_JACA_URL);
            }
            else{
                this.$router.push('/castillos/tarifas/'+this.monumentoUrl+'/'+event.entradaId)
            }
        },
        goToEvento(event){
            this.$router.push('/castillos/eventos/'+this.monumentoUrl+'/'+event.entradaId)
        },
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.monumentoUrl)
        },
        getPrice( precioOrientativo ){
            if( precioOrientativo ){
                if( isNaN(Number(precioOrientativo)) ){
                    return '';
                }else{
                    return `${parseFloat(Number(precioOrientativo))?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}`
                }
            }else{
                return '';
            }
        },
        async getAgrupacionData() {
            try {
                const result = await ApiService.getMonumentAgrupation(this.monumentoId);
                console.log('Agrupación:', result.data);
                
                if (result.data && result.data.length > 0) {
                    this.monumentosAgrupados = await Promise.all(
                        result.data.map(async (monumento) => {
                            const [visitas, eventos, paquetes] = await Promise.all([
                                ApiService.getVisitasByEdificacion(this.$i18n.locale, monumento._id),
                                ApiService.getEventosByMonumento(this.$i18n.locale, monumento._id),
                                ApiService.getPaquetes(monumento._id)
                            ]);

                            return {
                                monumentoId: monumento._id,
                                nombre: monumento.nombre,
                                servicios: {
                                    visitas: visitas.data.filter(e => e.restringirVentaIndividual !== true),
                                    eventos: eventos.data.filter(resultado => resultado.fechas.length > 0)
                                                    .filter(e => e.restringirVentaIndividual !== true),
                                    paquetes: paquetes.data ? paquetes.data.filter(r => r.tipo_de_paquete === 'pack_monumentos' && r.web !== false && r.web !== 0) : []
                                }
                            };
                        })
                    );
                }
            } catch (error) {
                console.log('Error al obtener agrupación:', error);
            }
        }
    },
    computed:{
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            else if($vm.$i18n.locale == 'ja'){
                idiomaIndex = 4;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        monumentoNombre(){
            let $vm = this;
            // console.log($vm.monumentoNombres)
            try{
                return $vm.monumentName;
            }
            catch(err){
                return "";
            }
        },

    },
    async mounted(){
        let $vm = this;
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
        // $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
        $vm.monumentoUrl = $vm.$route.params.monumentoNombre;
        $vm.loading = true;
        const _language = this.$store.getters.getIdioma;
        
        try {
            const result = await ApiService.getEdificacion(_language, $vm.monumentoUrl);
            let data = result.data;
            $vm.monumentoNombres = result.data.nombres;
            $vm.address = data.direccion;
            $vm.monumentoId = data.id;
            this.monumentName = data.webName;
            $vm.clorianPos = data.clorianPos;

            // Agregamos la llamada a getAgrupacionData después de obtener el monumentoId
            await this.getAgrupacionData();

            this.apiDeVentasActive = data.apiDeVentasActive;
            this.apiDeVentasUrl = data.apiDeVentasUrl;

            if(data.apiDeVentasActive == true && data.apiDeVentasUrl == 'clorian'){
                $vm.clorian = true;
                const response = await ClorianService.consultarProductos(data.nombreNormalizado, $vm.clorianPos);
                $vm.loading = false;
                $vm.entradas = [...response.data.products];
            } else {
                const [visitasResult, eventosResult, paquetesResult] = await Promise.all([
                    ApiService.getVisitasByEdificacion(_language, $vm.monumentoId),
                    ApiService.getEventosByMonumento(_language, $vm.monumentoId),
                    ApiService.getPaquetes(this.monumentoId)
                ]);

                $vm.loading = false;
                $vm.entradas = [...visitasResult.data.filter(e => e.restringirVentaIndividual !== true)];
                $vm.eventos = eventosResult.data.filter(resultado => resultado.fechas.length > 0).filter(e => e.restringirVentaIndividual !== true);
                this.paquetes = paquetesResult.data.filter(r => r.tipo_de_paquete === 'pack_monumentos');
            }
        } catch (err) {
            console.log("Error en la carga de datos:", err);
            $vm.loading = false;
        }
    },
}
</script>

<style scoped>
.mobile-cards-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 15px;
}
</style>