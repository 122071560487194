var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        d: "M253.7 232.4h-11l-8.7-95.8c-0.1-0.9-0.8-1.6-1.8-1.6H188c-0.9 0-1.7 0.7-1.8 1.6l-2.4 26.6c-0.1 1 0.6 1.8 1.6 1.9c0.9 0.1 1.8-0.6 1.9-1.5c0 0 0 0 0-0.1l2.3-25h41.1l8.5 93.9H46.3c-1-0.1-1.8 0.7-1.9 1.6c-0.1 1 0.7 1.8 1.6 1.9c0.1 0 0.2 0 0.3 0h207.4c1 0.1 1.8-0.7 1.9-1.6c0.1-1-0.7-1.8-1.6-1.9C253.9 232.3 253.8 232.3 253.7 232.4L253.7 232.4z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M196.3 209.4c-1 0-1.8-0.8-1.8-1.8c0 0 0 0 0 0v-17.7c0-1 0.8-1.8 1.8-1.8c1 0 1.8 0.8 1.8 1.8c0 0 0 0 0 0\n\tv17.7C198.1 208.6 197.3 209.4 196.3 209.4z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M154.3 154.4v17.7c0 1-0.8 1.8-1.7 1.8c0 0 0 0 0 0h-17.7c-1 0-1.8-0.8-1.8-1.8v-17.8c0-0.1 0-0.2 0.1-0.4c0-0.1 0.1-0.2 0.1-0.4l0.1-0.2c0.3-0.5 0.9-0.8 1.5-0.8c1 0 1.8 0.8 1.8 1.8c0 0 0 0 0 0v16h14.2v-16c0-1 0.8-1.8 1.7-1.8c0.6 0 1.1 0.3 1.5 0.8l0.1 0.2c0.1 0.1 0.1 0.2 0.1 0.3c0 0 0 0.1 0 0.1C154.3 154.2 154.3 154.3 154.3 154.4z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M214.6 197h-8.9c-1 0-1.8 0.8-1.8 1.8c0 0 0 0 0 0v7.1h-23v-2.6c0-3.4-2.8-6.2-6.2-6.2c-3.4 0-6.2 2.8-6.2 6.2v2.6h-5.3v-2.6c0-3.4-2.8-6.2-6.2-6.2c-3.4 0-6.2 2.8-6.2 6.2c0 0 0 0 0 0v2.6h-5.3v-2.6c-0.1-3.4-3-6.1-6.5-6c-3.2 0.1-5.8 2.7-6 6v2.6h-16c-0.3 0-0.5 0.1-0.8 0.2l-1.3-14.4h90.6c0.6 0 1.1-0.3 1.5-0.8c0.3-0.5 0.4-1.1 0.2-1.6l-8.8-26.6c-0.2-0.7-0.9-1.2-1.7-1.2h-46v3.6h44.8l7.6 23h-88.5l-2.1-23h24v-3.6h-24.3l-2.2-24.9v-15.9h7.1c1 0 1.8-0.8 1.8-1.8v-17.7c0-1-0.8-1.8-1.8-1.8h-8.8c-1 0-1.8 0.8-1.8 1.8v7.1h-5.3v-7.1c0-1-0.8-1.8-1.7-1.8c0 0 0 0 0 0h-8.8c-1 0-1.8 0.8-1.8 1.8v7.1h-5.3v-7.1c0-1-0.8-1.8-1.8-1.8h-8.9c-1 0-1.8 0.8-1.8 1.8c0 0 0 0 0 0v7.1h-5.3v-7.1c0-1-0.8-1.8-1.8-1.8h-8.9c-1 0-1.8 0.8-1.8 1.8V119c0 1 0.8 1.8 1.8 1.8h7.1v15.9l-8.7 95.8l-0.2 1.6c-0.1 1 0.6 1.8 1.6 1.9h0.2c0.9 0 1.7-0.7 1.8-1.6l0.2-1.9l8.5-93.9h41l2.1 23l0.3 3.6l6.1 67.3l0.2 1.9c0.1 0.9 0.8 1.6 1.8 1.6h97.5c1 0 1.8-0.8 1.8-1.8v-35.4C216.3 197.8 215.5 197 214.6 197z M106.5 135H65.8v-14.2h40.8V135z M56.9 117.2V103h5.3v7.1c0 1 0.8 1.8 1.8 1.8c0 0 0 0 0 0h8.9c1 0 1.8-0.8 1.8-1.8V103h5.3v7.1c0 1 0.8 1.8 1.8 1.8h8.8c1 0 1.8-0.8 1.8-1.8V103h5.3v7.1c0 1 0.8 1.8 1.7 1.8c0 0 0 0 0 0h8.9c1 0 1.8-0.8 1.8-1.8V103h5.3v14.2H56.9z M172.1 203.1c0.1-1.5 1.4-2.6 2.8-2.5c1.3 0.1 2.4 1.2 2.5 2.5v2.6h-5.3V203.1z M154.3 203.1c0.1-1.5 1.4-2.6 2.8-2.5c1.3 0.1 2.4 1.2 2.5 2.5v2.6h-5.3V203.1z M136.6 203.1c0-1.5 1.2-2.6 2.7-2.6c1.5 0 2.6 1.2 2.6 2.6v2.6h-5.3V203.1z M212.8 232.4h-94.1l-2.1-23.1c0.2 0 0.3 0 0.5 0h88.6c1 0 1.8-0.8 1.8-1.8c0 0 0 0 0 0v-7h5.3L212.8 232.4z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M77.3 143.8c-3.4 0-6.2 2.8-6.2 6.2v13.3c0 1 0.8 1.8 1.7 1.8c0 0 0 0 0 0h8.9c1 0 1.8-0.8 1.8-1.8V150C83.5 146.6 80.7 143.8 77.3 143.8z M79.9 161.5h-5.3V150c0-1.5 1.2-2.7 2.7-2.7c1.5 0 2.7 1.2 2.7 2.7V161.5z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M95 170.4c-3.4 0-6.2 2.8-6.2 6.2v13.3c0 1 0.8 1.8 1.8 1.8h8.8c1 0 1.8-0.8 1.8-1.7c0 0 0 0 0 0v-13.3C101.2 173.2 98.4 170.4 95 170.4z M97.7 188.1h-5.4v-11.5c0.1-1.5 1.4-2.6 2.8-2.5c1.3 0.1 2.4 1.2 2.5 2.5L97.7 188.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M154.3 154.1c0 0 0-0.1 0-0.1c0-0.1-0.1-0.2-0.1-0.3l-0.1-0.2l-8.7-17.5c-0.5-0.9-1.7-1.2-2.5-0.6c-0.3 0.2-0.5 0.4-0.6 0.6l-8.8 17.5l-0.1 0.2c-0.1 0.1-0.1 0.2-0.1 0.4c0 0.1 0 0.2-0.1 0.4l0 0c0 1 0.8 1.8 1.8 1.8h17.7c0.6 0 1.2-0.3 1.5-0.8c0.2-0.2 0.2-0.5 0.2-0.8v-0.2C154.3 154.3 154.3 154.2 154.3 154.1z M137.7 152.7l6-12l6 12H137.7z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M241.1 99.5h-8.8c-1 0-1.8 0.8-1.8 1.8c0 0 0 0 0 0v7.1h-5.3v-16c0-1-0.8-1.8-1.8-1.8h-15.9v-6l16.7-8.4c0.9-0.5 1.2-1.5 0.8-2.4c-0.2-0.3-0.4-0.6-0.8-0.8l-17.5-8.7l-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1 0-0.1-0.1-0.1-0.1c-0.1 0-0.3 0-0.4-0.1c-0.1 0-0.1 0-0.2 0c-0.1 0-0.2 0-0.3 0.1c0 0-0.1 0-0.1 0c-0.1 0-0.2 0.1-0.2 0.1l0 0c-0.1 0-0.2 0.1-0.2 0.2l0 0c-0.1 0-0.1 0.1-0.2 0.2s0 0 0 0s-0.1 0.2-0.2 0.3c0 0 0 0.1-0.1 0.1l0 0c0 0 0 0.1 0 0.1l0 0c0 0 0 0.1-0.1 0.2c0 0.1 0 0.2 0 0.3c0 0 0 0.1 0 0.1v24.8H197c-1 0-1.8 0.8-1.8 1.8v16H190v-7.1c-0.1-1-0.9-1.7-1.9-1.6c0 0 0 0 0 0h-8.9c-1 0-1.8 0.8-1.8 1.7c0 0 0 0 0 0V119c0 1 0.8 1.8 1.7 1.8c0 0 0 0 0 0h7.1v16c0 1 0.8 1.7 1.8 1.7h44.3c1 0 1.8-0.8 1.8-1.8c0 0 0 0 0 0v-16h7.1c1 0 1.8-0.8 1.8-1.8v-17.7C242.9 100.3 242.1 99.5 241.1 99.5z M207.5 68.7l12 6l-12 6V68.7z M198.6 94.2h23v5.3h-7.1c-1 0-1.8 0.8-1.8 1.8v7.1h-5.3v-7.1c0-1-0.8-1.8-1.7-1.8c0 0 0 0 0 0h-7.1L198.6 94.2z M230.5 135h-40.8v-14.2h40.8V135z M239.4 117.3h-58.5V103h5.3v7.1c0 1 0.8 1.8 1.8 1.8h8.9c1 0 1.8-0.8 1.8-1.8V103h5.4v7.1c0 1 0.8 1.8 1.8 1.8l0 0h8.9c1 0 1.8-0.8 1.8-1.8V103h5.3v7.1c0 1 0.8 1.8 1.8 1.8h8.9c1 0 1.8-0.8 1.8-1.8c0 0 0 0 0 0V103h5.3L239.4 117.3z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M219 143.8c-3.4 0-6.2 2.8-6.2 6.2c0 0 0 0 0 0v13.3c0 1 0.8 1.8 1.8 1.8h8.8c1 0 1.8-0.8 1.8-1.8V150C225.2 146.6 222.4 143.8 219 143.8z M221.6 161.5h-5.3V150c0-1.5 1.2-2.6 2.7-2.6c1.5 0 2.6 1.2 2.6 2.6V161.5z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }