<script>
import ApiDataService from '../../services/ApiService';

export default {
    data() {
        return {
            username: '',
            password: '',
            confirmPassword: '',
            showWarning: false,
            errors: {
                password: '',
                username: ''
            }
        }
    },
    computed: {
        isFormValid() {
            return this.username && 
                   this.password && 
                   this.confirmPassword && 
                   this.password === this.confirmPassword &&
                   !this.errors.password &&
                   !this.errors.username;
        }
    },
    watch: {
        password() {
            this.validatePasswords();
        },
        confirmPassword() {
            this.validatePasswords();
        },
        username() {
            this.validateUsername();
        }
    },
    methods: {
        validateUsername() {
            if (!this.username) {
                this.errors.username = this.$t('default.PRIVACY.USERNAME_REQUIRED');
            } else {
                this.errors.username = '';
            }
        },
        validatePasswords() {
            if (!this.password) {
                this.errors.password = this.$t('default.PRIVACY.PASSWORD_REQUIRED');
            } else if (!this.confirmPassword) {
                this.errors.password = this.$t('default.PRIVACY.CONFIRM_PASSWORD_REQUIRED');
            } else if (this.password !== this.confirmPassword) {
                this.errors.password = this.$t('default.PRIVACY.PASSWORDS_DO_NOT_MATCH');
            } else {
                this.errors.password = '';
            }
        },
        clearForm() {
            this.username = '';
            this.password = '';
            this.confirmPassword = '';
            this.showWarning = false;
            this.errors = { password: '', username: '' };
        },
        async removeAccount() {
            try {
                this.validateUsername();
                this.validatePasswords();

                if (!this.isFormValid) {
                    return;
                }

                if (!this.showWarning) {
                    this.showWarning = true;
                    return;
                }

                const result = await ApiDataService.removeAccount(this.username, this.password);
                if (result.status === 200 && result.data.success) {
                    this.$toast.success(this.$t("default.PRIVACY.REMOVE_ACCOUNT_SUCCESS"));
                    this.clearForm();
                } else {
                    this.$toast.error(this.$t("default.PRIVACY.REMOVE_ACCOUNT_ERROR"));
                }
            } catch (error) {
                this.$toast.error(this.$t("default.PRIVACY.REMOVE_ACCOUNT_ERROR"));
            }
        }
    }
}

</script>

<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex py-3 blog-main"
            style="height:auto; min-height:75px; width:100%">
            <p class="my-auto ps-4 spainhn-subtitle-2 text-white">{{ $t('default.PRIVACY.REMOVE_ACCOUNT') }}</p>
        </div>

        <div class="container">
            <div class="remove-account-card">
                <div class="warning-header">
                    <i class="fas fa-exclamation-triangle warning-icon"></i>
                    <h1>{{ $t('default.PRIVACY.REMOVE_ACCOUNT') }}</h1>
                </div>

                <div class="warning-text">
                    <p>{{ $t('default.PRIVACY.REMOVE_ACCOUNT_WARNING') }}</p>
                </div>

                <div class="form-container">
                    <div class="form-group">
                        <label for="username">{{ $t('default.PRIVACY.USERNAME') }}</label>
                        <input 
                            type="text" 
                            id="username" 
                            v-model="username" 
                            :class="{'form-control': true, 'error': errors.username}"
                            @blur="validateUsername"
                        >
                        <span v-if="errors.username" class="error-message">{{ errors.username }}</span>
                    </div>
                    <div class="form-group">
                        <label for="password">{{ $t('default.PRIVACY.PASSWORD') }}</label>
                        <input 
                            type="password" 
                            id="password" 
                            v-model="password" 
                            :class="{'form-control': true, 'error': errors.password}"
                            @blur="validatePasswords"
                        >
                    </div>
                    <div class="form-group">
                        <label for="confirmPassword">{{ $t('default.PRIVACY.CONFIRM_PASSWORD') }}</label>
                        <input 
                            type="password" 
                            id="confirmPassword" 
                            v-model="confirmPassword" 
                            :class="{'form-control': true, 'error': errors.password}"
                            @blur="validatePasswords"
                        >
                        <span v-if="errors.password" class="error-message">{{ errors.password }}</span>
                    </div>

                    <div v-if="showWarning" class="final-warning">
                        <p>{{ $t('default.PRIVACY.REMOVE_ACCOUNT_FINAL_WARNING') }}</p>
                    </div>

                    <button 
                        @click="removeAccount" 
                        class="delete-button" 
                        :class="{ 'confirm': showWarning, 'disabled': !isFormValid }" 
                        :disabled="!isFormValid"
                    >
                        {{ showWarning ? $t('default.PRIVACY.YES_DELETE_MY_ACCOUNT') :
                            $t('default.PRIVACY.REMOVE_ACCOUNT') }}
                    </button>
                </div>
            </div>
        </div>
    </default-layout>
</template>
<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1536px;
    margin: 0 auto;
    padding: 2rem;
}

.remove-account-card {
    width: 100%;
    max-width: 500px;
    padding: 2rem;
    border-radius: 12px;
    background: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border: 1px solid #fee2e2;
}

.warning-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #fee2e2;
}

.warning-icon {
    font-size: 1.5rem;
    color: #dc2626;
}

h1 {
    color: #dc2626;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

.warning-text {
    background-color: #fff5f5;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1.5rem;
}

.warning-text p {
    color: #991b1b;
    margin: 0;
    font-size: 0.875rem;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
}

.form-control {
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s;
}

.form-control:focus {
    outline: none;
    border-color: #dc2626;
    box-shadow: 0 0 0 3px rgba(220, 38, 38, 0.1);
}

.final-warning {
    background-color: #fff5f5;
    border-radius: 6px;
    padding: 1rem;
    margin-top: 0.5rem;
}

.final-warning p {
    color: #dc2626;
    font-weight: 500;
    margin: 0;
    font-size: 0.875rem;
}

.delete-button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 6px;
    background-color: #dc2626;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.delete-button:hover {
    background-color: #b91c1c;
}

.delete-button.confirm {
    background-color: #991b1b;
}

@media (max-width: 640px) {
    .container {
        padding: 1rem;
    }

    .remove-account-card {
        padding: 1.5rem;
    }
}

.form-control.error {
    border-color: #dc2626;
    background-color: #fff5f5;
}

.error-message {
    color: #dc2626;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.delete-button.disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
}

.delete-button.disabled:hover {
    background-color: #9ca3af;
}
</style>