var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("white-loader", { attrs: { loading: _vm.loading } }),
      _vm.loading
        ? _c("div", { staticStyle: { "min-height": "500px" } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "container-fluid px-5 mt-4" },
        _vm._l(_vm.monumentosAgrupados, function (monumento) {
          return _c(
            "div",
            { key: monumento.monumentoId, staticClass: "mb-4" },
            [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "spainhn-sections-title uppercase",
                    staticStyle: {
                      "font-size": "1.4rem",
                      "margin-bottom": "1.5rem",
                    },
                  },
                  [_vm._v(_vm._s(monumento.nombre))]
                ),
              ]),
              monumento.servicios.visitas.length > 0
                ? _c("div", [
                    _c("p", { staticClass: "spainhn-sections-title" }, [
                      _vm._v(_vm._s(_vm.$t("default.MICROSITES_BOOKING"))),
                    ]),
                    _c("div", { staticClass: "d-md-flex d-none mb-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                        },
                        _vm._l(
                          monumento.servicios.visitas,
                          function (entrada, index) {
                            return _c("cards", {
                              key: index,
                              attrs: {
                                entradaId: entrada.visitaId,
                                monumentoNombreURL: _vm.monumentoUrl,
                                imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`,
                                apiDeVentasUrl: _vm.apiDeVentasActive
                                  ? `${_vm.apiDeVentasUrl}?idProducto=${entrada.oldId}`
                                  : "",
                                title: entrada.webName,
                                monument: entrada.monumentName,
                                precioOrientativo: entrada.precioOrientativo
                                  ? _vm.$t("default.CARDS.START_FROM", {
                                      msg: _vm.getPrice(
                                        entrada.precioOrientativo
                                      ),
                                    })
                                  : "",
                              },
                              on: {
                                goToEntrada: function ($event) {
                                  return _vm.goToEntrada($event)
                                },
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mobile-cards-container d-md-none" },
                      _vm._l(
                        monumento.servicios.visitas,
                        function (entrada, index) {
                          return _c("recommendations-card", {
                            key: index,
                            attrs: {
                              title: entrada.webName,
                              showDefault: false,
                              text: _vm.$t("default.CARDS.PLACE", {
                                msg: entrada.monumentName,
                              }),
                              "price-description": entrada.precioOrientativo
                                ? _vm.$t("default.CARDS.START_FROM", {
                                    msg: _vm.getPrice(
                                      entrada.precioOrientativo
                                    ),
                                  })
                                : "",
                              "image-url": `${_vm.imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`,
                              showBuyButton: true,
                            },
                            on: {
                              goTo: function ($event) {
                                return _vm.goToEntrada({
                                  entradaId: entrada.visitaId,
                                })
                              },
                              buy: () =>
                                _vm.goToBuy(entrada.visitaId, entrada.oldId),
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ])
                : _vm._e(),
              monumento.servicios.paquetes.length > 0
                ? _c("div", [
                    _c("p", { staticClass: "spainhn-sections-title" }, [
                      _vm._v("Tours"),
                    ]),
                    _c("div", { staticClass: "d-md-flex d-none mb-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                        },
                        _vm._l(
                          monumento.servicios.paquetes,
                          function (paqueteM) {
                            return _c("ResultsCards", {
                              key: paqueteM._id,
                              staticClass: "mx-auto",
                              attrs: {
                                title: paqueteM.name,
                                entradaId: paqueteM._id,
                                showDefault: false,
                                monumentoNombreURL: paqueteM.name,
                                monument: _vm.$t("default.CARDS.PLACE", {
                                  msg: paqueteM.monumentoId.nombre,
                                }),
                                precioOrientativo: paqueteM?.precioOrientativo
                                  ? _vm.$t("default.CARDS.START_FROM", {
                                      msg: _vm.getPrice(
                                        paqueteM?.precioOrientativo
                                      ),
                                    })
                                  : "",
                                imageUrl: `${_vm.imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`,
                                tipo: "paquetes_monumentos",
                              },
                              on: {
                                goToEntrada: () =>
                                  _vm.goToMicrositioPaqueteMonumento({
                                    name: paqueteM.name,
                                    id: paqueteM._id,
                                  }),
                                buy: () => _vm.goToBuy(paqueteM, true),
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mobile-cards-container d-md-none" },
                      _vm._l(monumento.servicios.paquetes, function (paqueteM) {
                        return _c("recommendations-card", {
                          key: paqueteM._id,
                          attrs: {
                            title: paqueteM.name,
                            showDefault: false,
                            text: _vm.$t("default.CARDS.PLACE", {
                              msg: paqueteM.monumentoId.nombre,
                            }),
                            "price-description": paqueteM.precioOrientativo
                              ? _vm.$t("default.CARDS.START_FROM", {
                                  msg: _vm.getPrice(paqueteM.precioOrientativo),
                                })
                              : "",
                            "image-url": `${_vm.imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`,
                            showBuyButton: true,
                          },
                          on: {
                            goTo: () =>
                              _vm.goToMicrositioPaqueteMonumento({
                                name: paqueteM.name,
                                id: paqueteM._id,
                              }),
                            buy: () => _vm.goToBuy2(paqueteM, true),
                          },
                        })
                      }),
                      1
                    ),
                  ])
                : _vm._e(),
              monumento.servicios.eventos.length > 0
                ? _c("div", [
                    _c("p", { staticClass: "spainhn-sections-title" }, [
                      _vm._v(
                        _vm._s(_vm.$t("default.MAIN_MENU_SERVICES_EVENTOS"))
                      ),
                    ]),
                    _c("div", { staticClass: "d-md-flex d-none mb-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                        },
                        _vm._l(
                          monumento.servicios.eventos,
                          function (evento, index) {
                            return _c("cards", {
                              key: index,
                              attrs: {
                                tipo: "Evento",
                                entradaId: evento.visitaId,
                                monumentoNombreURL: _vm.monumentoUrl,
                                imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`,
                                title: evento.webName,
                                monument: monumento.nombre,
                                precioOrientativo: evento.precioOrientativo
                                  ? _vm.$t("default.CARDS.START_FROM", {
                                      msg: _vm.getPrice(
                                        evento.precioOrientativo
                                      ),
                                    })
                                  : "",
                                date: evento.fechas,
                              },
                              on: {
                                goToEntrada: function ($event) {
                                  return _vm.goToEntrada($event)
                                },
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mobile-cards-container d-md-none" },
                      _vm._l(
                        monumento.servicios.eventos,
                        function (evento, index) {
                          return _c("recommendations-card", {
                            key: index,
                            attrs: {
                              title: evento.webName,
                              showDefault: false,
                              text: _vm.$t("default.CARDS.PLACE", {
                                msg: monumento.nombre,
                              }),
                              "price-description": evento.precioOrientativo
                                ? _vm.$t("default.CARDS.START_FROM", {
                                    msg: _vm.getPrice(evento.precioOrientativo),
                                  })
                                : "",
                              "image-url": `${_vm.imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`,
                              showBuyButton: true,
                            },
                            on: {
                              goTo: function ($event) {
                                return _vm.goToEntrada({
                                  entradaId: evento.visitaId,
                                })
                              },
                              buy: () =>
                                _vm.goToBuy(evento.visitaId, evento.oldId),
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _c(
        "mdb-container",
        { staticClass: "mt-3", attrs: { fluid: "" } },
        [_c("ComponentsCloud")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }