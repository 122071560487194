var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex py-3 blog-main",
        staticStyle: { height: "auto", "min-height": "75px", width: "100%" },
      },
      [
        _c("p", { staticClass: "my-auto ps-4 spainhn-subtitle-2 text-white" }, [
          _vm._v(_vm._s(_vm.$t("default.PRIVACY.REMOVE_ACCOUNT"))),
        ]),
      ]
    ),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "remove-account-card" }, [
        _c("div", { staticClass: "warning-header" }, [
          _c("i", { staticClass: "fas fa-exclamation-triangle warning-icon" }),
          _c("h1", [_vm._v(_vm._s(_vm.$t("default.PRIVACY.REMOVE_ACCOUNT")))]),
        ]),
        _c("div", { staticClass: "warning-text" }, [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("default.PRIVACY.REMOVE_ACCOUNT_WARNING"))),
          ]),
        ]),
        _c("div", { staticClass: "form-container" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "username" } }, [
              _vm._v(_vm._s(_vm.$t("default.PRIVACY.USERNAME"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username",
                },
              ],
              class: { "form-control": true, error: _vm.errors.username },
              attrs: { type: "text", id: "username" },
              domProps: { value: _vm.username },
              on: {
                blur: _vm.validateUsername,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.username = $event.target.value
                },
              },
            }),
            _vm.errors.username
              ? _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.username)),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v(_vm._s(_vm.$t("default.PRIVACY.PASSWORD"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              class: { "form-control": true, error: _vm.errors.password },
              attrs: { type: "password", id: "password" },
              domProps: { value: _vm.password },
              on: {
                blur: _vm.validatePasswords,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "confirmPassword" } }, [
              _vm._v(_vm._s(_vm.$t("default.PRIVACY.CONFIRM_PASSWORD"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.confirmPassword,
                  expression: "confirmPassword",
                },
              ],
              class: { "form-control": true, error: _vm.errors.password },
              attrs: { type: "password", id: "confirmPassword" },
              domProps: { value: _vm.confirmPassword },
              on: {
                blur: _vm.validatePasswords,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.confirmPassword = $event.target.value
                },
              },
            }),
            _vm.errors.password
              ? _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.password)),
                ])
              : _vm._e(),
          ]),
          _vm.showWarning
            ? _c("div", { staticClass: "final-warning" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("default.PRIVACY.REMOVE_ACCOUNT_FINAL_WARNING")
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "delete-button",
              class: { confirm: _vm.showWarning, disabled: !_vm.isFormValid },
              attrs: { disabled: !_vm.isFormValid },
              on: { click: _vm.removeAccount },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.showWarning
                      ? _vm.$t("default.PRIVACY.YES_DELETE_MY_ACCOUNT")
                      : _vm.$t("default.PRIVACY.REMOVE_ACCOUNT")
                  ) +
                  " "
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }